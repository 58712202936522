const validationMessage = $("#validation-message")
const requiredFields = $(".required");
const form = $('form')

function form_errors() {
    let errors = ''

    if (required_field_errors() !== '') {
        errors += ' Please fill in required fields'
    }
    return errors
}

function required_field_errors() {
    let errors = ''
    requiredFields.each(
        function () {
            if (invalid_field($(this)) && $(this).is(":visible")) {
                errors = ' Cannot Be Blank!';
            }
        }
    );
    return errors;
}

function invalid_field(field) {
    let error = null;
    if (field.val() === '') {
        error = 'Cannot be Blank';
    }
    if (error) {
        add_error(field, error)
    } else {
        clear_error(field)
    }
    return error;
}

function add_error(field, error) {
    const error_field_id = field.attr('name') + '_error'
    field.addClass("error");
    field.css("background-color", "#f2dede");
    field.css("color", '#8B0000');
    const error_html = '<p id="' + error_field_id + '" style="color:#8B0000;" >' + error + '</p>'
    field.after(error_html)
}

function clear_error(field) {
    const error_field_id = field.attr('name') + '_error'
    field.removeClass("error");
    field.css("background-color", "#ffffff");
    field.css("color", "black");
    $('#' + error_field_id).hide()
}

function submit_form() {
    const errors = form_errors()
    if (errors === '') {
        validationMessage.hide();
        form.submit();
    } else {
        validationMessage.text('There are errors preventing this form from being saved.' + errors)
        validationMessage.show();
    }
}

export {submit_form, clear_error, add_error};